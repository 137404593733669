import AgoraRTM from "agora-rtm-sdk";
import EventEmitter from "events";
import { getStore } from "@/utils/store";
import { AGORA_RTM_KEY } from "@/const/config";

// 你的 app ID
const appID = AGORA_RTM_KEY;

window.$rtm = null;

export default class RTMClient extends EventEmitter {
  constructor() {
    super();
    this.channels = {};
    this._logined = false;
    this.init();
    window.$rtm = this;
  }

  init() {
    this.client = AgoraRTM.createInstance(appID);
    this.subscribeClientEvents();
  }

  // subscribe client events
  subscribeClientEvents() {
    const clientEvents = ["ConnectionStateChanged", "MessageFromPeer"];
    clientEvents.forEach((eventName) => {
      this.client.on(eventName, (...args) => {
        // console.log("emit 1", eventName, ...args);
        // log event message
        this.emit(eventName, ...args);
      });
    });
  }

  // subscribe channel events
  subscribeChannelEvents(channelName, handleMessage) {
    const channelEvents = ["ChannelMessage", "MemberJoined", "MemberLeft"];
    channelEvents.forEach((eventName) => {
      this.channels[channelName].channel.on(eventName, (...args) => {
        handleMessage && handleMessage({ channelName, args: args });
        this.emit(eventName, { channelName, args: args });
      });
    });
  }

  async login(accountName, token) {
    this.accountName = `${accountName}`;
    return this.client.login({ uid: this.accountName, token });
  }

  async logout() {
    return this.client.logout();
  }

  async joinChannel(name, handleMessage) {
    const channel = this.client.createChannel(name);
    this.channels[name] = {
      channel,
      joined: true, // channel state
    };
    this.subscribeChannelEvents(name, handleMessage);
    return channel.join();
  }

  async leaveChannel(name) {
    console.log("leaveChannel", name);
    if (!this.channels[name] || (this.channels[name] && !this.channels[name].joined)) return;
    return this.channels[name].channel.leave();
  }

  async sendChannelMessage(text, channelName) {
    console.log(text, channelName, "sendChannelMessage");
    if (!this.channels[channelName] || !this.channels[channelName].joined) return;
    const temp = text;
    return this.channels[channelName].channel.sendMessage({ text: JSON.stringify(temp) });
  }

  async sendPeerMessage(text, peerId) {
    console.log("sendPeerMessage", text, peerId);
    return this.client.sendMessageToPeer({ text }, peerId.toString());
  }

  async queryPeersOnlineStatus(memberId) {
    console.log("queryPeersOnlineStatus", memberId);
    return this.client.queryPeersOnlineStatus([memberId]);
  }

  //send image
  async uploadImage(blob, peerId) {
    const mediaMessage = await this.client.createMediaMessageByUploading(blob, {
      messageType: "IMAGE",
      fileName: "agora.jpg",
      description: "send image",
      thumbnail: blob,
      // width: 100,
      // height: 200,
      // thumbnailWidth: 50,
      // thumbnailHeight: 200,
    });
    return this.client.sendMessageToPeer(mediaMessage, peerId);
  }

  async sendChannelMediaMessage(blob, channelName) {
    console.log("sendChannelMessage", blob, channelName);
    if (!this.channels[channelName] || !this.channels[channelName].joined) return;
    const mediaMessage = await this.client.createMediaMessageByUploading(blob, {
      messageType: "IMAGE",
      fileName: "agora.jpg",
      description: "send image",
      thumbnail: blob,
      // width: 100,
      // height: 200,
      // thumbnailWidth: 50,
      // thumbnailHeight: 200,
    });
    return this.channels[channelName].channel.sendMessage(mediaMessage);
  }

  async cancelImage(message) {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), 1000);
    await this.client.downloadMedia(message.mediaId, {
      cancelSignal: controller.signal,
      onOperationProgress: ({ currentSize, totalSize }) => {
        console.log(currentSize, totalSize);
      },
    });
  }
}

export function sendChannelMessage() {}

export function MessageFormat(text, type) {
  const user = getStore({ name: "info" }) || {};

  return {
    awardList: [],
    box_class: "1",
    cp_tx: "",
    cp_xssm: "",
    exp: "",
    headimgurl: user.headimgurl,
    hot: "",
    hz_img: "",
    location: [0, 0],
    ltk: "",
    ltk_left: "",
    ltk_right: "",
    message: text,
    messageType: type || "1",
    name: "",
    nickName: user.nickname,
    nick_color: "#ffffff",
    toNickName: "",
    toNick_color: "",
    toUser_id: "",
    toheadimgurl: "",
    user_id: user.id,
    vip_img: "",
    vip_tx: user.vip_level,
  };
}

export function MessageDeserialize(jsonStr) {
  return JSON.parse(jsonStr);
}
