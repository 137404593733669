import Vue from "vue";
import popupsleek from "./_index.vue";
let Instance = {};

export async function PopupSleek(com, opt, self) {
  const { default: c } = await com();
  const key = c.name; // 目的是同名不创建新的实例
  if (!key) {
    return console.error("组件缺少[name]属性");
  }
  if (!Instance[key]) {
    let Constructor = Vue.extend(popupsleek);
    Instance[key] = new Constructor({
      components: {
        com,
      },
      el: document.createElement("div"),
    });
    Instance[key].$mount();
    document.body.appendChild(Instance[key].$el);
  }

  return new Promise((res) => {
    Instance[key].show(
      {
        ...opt,
      },
      (data) => {
        return res(data);
      },
      self
    );
  });
}

// 使用方式
// const res = await PopupSleek(() => import("@/views/my/index.vue"), {
//   closeable: true,
// });
