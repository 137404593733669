import request from "./request";
/** 家族列表*/
export function getFamilyList(data) {
  return request.post("/api/getFamilyList", data);
}
/** 家族申请列表*/
export function getFamilyApllyList(data) {
  return request.post("/api/getFamilyApllyList", data);
}
/** 家族申请--同意*/
export function actionAgreeFamily(data) {
  return request.post("/api/actionAgreeFamily", data);
}
/** 家族申请--拒绝*/
export function actionRefuseFamily(data) {
  return request.post("/api/actionRefuseFamily", data);
}
/** 申请加入家族*/
export function actionApplyFamily(data) {
  return request.post("/api/actionApplyFamily", data);
}
/** 家族编辑保存*/
export function actionEditFamily(data) {
  return request.post("/api/actionEditFamily", data);
}
/** 创建家族*/
export function actionCreateFamily(data) {
  return request.post("/api/actionCreateFamily", data);
}
/** 同意加入家族*/
export function actionAgreeJoin(data) {
  return request.post("/api/actionAgreeJoin", data);
}
/** 拒绝加入家族*/
export function actionRefuseJoin(data) {
  return request.post("/api/actionRefuseJoin", data);
}
/** 获取我的家族*/
export function getMyFamilyInfo(data) {
  return request.post("/api/getMyFamilyInfo", data);
}
/** 获取家族详情*/
export function getFamilyDetail(data) {
  return request.post("/api/getFamilyDetail", data);
}
/** 获取家族详情*/
export function getFamilyMoreList(data) {
  return request.post("/api/getFamilyMoreList", data);
}
