// import Cookies from "js-cookie";
import { setStore, getStore, removeStore } from "@/utils/store";

const TokenKey = "token";

export function getToken() {
  return getStore({ name: TokenKey });
  // return Cookies.get(TokenKey);
}

export function setToken(token) {
  // return Cookies.set(TokenKey, token);
  return setStore({ name: TokenKey, content: token });
}

export function removeToken() {
  // return Cookies.remove(TokenKey);
  return removeStore({ name: "TokenKey" });
}

export function ualogin() {
  const [, data] = navigator.userAgent.split("&&");
  if (data) {
    const native = JSON.parse(data);
    native.token && setToken(native.token);
    window.native = native;
    return native;
  }
  return {};
}

export function getUA() {
  const [, data] = navigator.userAgent.split("&&");
  if (data) {
    const native = JSON.parse(data);
    return native;
  }
  return {};
}
