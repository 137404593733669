import reclick from "./reclick";
const install = function (Vue) {
  Vue.directive("re-click", reclick);
};

if (window.Vue) {
  // window.tips = reclick
  Vue.use(install); // eslint-disable-line
}

// reclick.install = install;
export default install;
