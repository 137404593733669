import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "root",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/index.vue"),
      },

      {
        path: "center",
        name: "center",
        component: () => import("@/views/center/index.vue"),
        children: [
          {
            path: "info",
            name: "center.info",
            component: () => import("@/views/center/info"),
          },
          {
            path: "wallet",
            name: "center.wallet",
            component: () => import("@/views/center/wallet"),
          },
          {
            path: "shop",
            name: "center.shop",
            component: () => import("@/views/center/shop"),
          }
        ],
      },
      {
        path: "order/detail/:id",
        name: 'center.order.detail',
        component: () => import("@/views/center/order/detail")
      },
      {
        path: "order/payfor/:id",
        name: 'center.order.payfor',
        component: () => import("@/views/center/order/payfor")
      },
      {
        path: "trade",
        name: "trade",
        component: () => import("@/views/trade/index.vue"),
      },
      {
        path: "trade/detail/:id",
        name: "trade.detail",
        component: () => import("@/views/trade/detail/index.vue"),
      },
      {
        path: "trade/pay/:id",
        name: "trade.pay",
        component: () => import("@/views/trade/pay/index.vue"),
      },
      {
        path: "trade/create/:id",
        name: "trade.create",
        component: () => import("@/views/trade/create/index.vue"),
      },
    ],
  },
  {
    path: "/room/:channelId",
    name: "room",
    component: () => import("@/views/room/index.vue"),
    // props: true,
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import('@/views/AboutView.vue'),
  // },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

export default router;
