<template>
  <div>
    <div class="drag-area"></div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style>
.drag-area {
  -webkit-app-region: drag;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 20px;
  /* cursor: move; */
}
</style>
