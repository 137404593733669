<template>
  <div class="layout" :class="{ 'is-home': isHome }">
    <div class="header">
      <div class="container mx-auto">
        <div class="flex">
          <div class="header-app" @click="$router.push({ name: 'home' })">
            <img class="header-app-logo" src="/static/images/logo.jpg" />
            <!-- <div class="header-app-name">UU开黑</div> -->
          </div>
          <div class="flex-item header-nav flex">
            <div class="flex-item text-right mr-5">
              <div class="search">
                <div class="search-box">
                  <div class="search-input"><input v-model="keywords" @focus="handleInputFocus" @blur="handleInputBlur" @keyup.enter="mergeSearch" placeholder="搜索名称、房间名称、ID" /></div>
                  <div class="search-submit">
                    <button @click="mergeSearch">
                      <i class="el-icon-search"></i>
                    </button>
                  </div>
                </div>
                <div class="absolute right-[0] top-[32px] text-left w-[250px] z-50 rounded-md mt-2 bg-[#fff]" v-if="showSearhList">
                  <div class="relative height-[200px]">
                    <div class="leading-4 t-12 t-000">
                      <div class="ml-3 mt-2">
                        历史搜素
                        <div class="mr-4 float-right cursor-pointer" @click="cleanSarhList">
                          <i class="el-icon-delete"></i>
                        </div>
                      </div>
                    </div>
                    <div class="ml-2" style="line-height: 28px">
                      <div v-for="list in searhList" class="inline pt-1 pb-1 pl-2 pr-2 t-12 t-000 ml-1 mr-1 rounded-md cursor-pointer" @click="clickSearhList(list.search)" style="background: #ddd" :key="list.id">{{ list.search }}</div>
                    </div>
                  </div>
                </div>
                <div class="absolute right-[0] top-[32px] text-left w-[400px] h-[500px] z-50 rounded-md mt-2 bg-[#fff]" v-if="showSearh">
                  <div class="leading-4 t-12 t-000">
                    <div class="ml-3 mt-2">
                      相关房间
                      <div class="mr-4 float-right cursor-pointer" @click="showSearh = false">
                        <i class="el-icon-close" style="font-size: 16px; color: deepskyblue"></i>
                      </div>
                    </div>
                  </div>
                  <div class="ml-2 mt-4 h-115" style="line-height: 28px">
                    <el-scrollbar style="height: 100%">
                      <div v-for="list in searhData.rooms" class="inline t-12 t-000 cursor-pointer" :key="list.id">
                        <div class="flex rounded-md w-95 ml-0.5 mb-3" style="box-shadow: 0px 0px 6px #ddd" @click="rnterRoom(list)">
                          <div class="w-11 h-11 ml-5">
                            <el-image class="w-full h-full rounded-lg" :src="list.headimgurl">
                              <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline" style="font-size: 44px"></i>
                              </div>
                            </el-image>
                            <!-- <img class="w-full h-full rounded-full" :src="list.headimgurl" /> -->
                          </div>
                          <div class="ml-3 mr-2 h-16 w-62 clanTitle">
                            <div class="leading-6 t-14 t-blue mt-2.5 overflow-ellipsis overflow-hidden">
                              {{ list.room_name }}
                            </div>
                            <div class="leading-6 t-12 t-gray overflow-ellipsis overflow-hidden">
                              <div class="w-4 h-4 ml-1 -mt-1 rounded-full inline-block align-middle t-12 text-center leading-5" :style="list.sex == 2 ? 'background-image: linear-gradient(90deg, #f56c6c, #efcdcd)' : 'background-image: linear-gradient(90deg, #00b4ff, #1a74ff)'">
                                <i :class="list.sex == 2 ? 'el-icon-female' : 'el-icon-male'"></i>
                              </div>
                              {{ list.nickname }} ID: {{ list.uid }}
                            </div>
                          </div>
                          <div class="t-12 t-000 -mt-2 w-12 text-center"><img class="w-6 h-6 ml-3 mt-3.5" src="../assets/imgs/hot.png" />{{ list.hot }}</div>
                        </div>
                      </div>
                    </el-scrollbar>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex nav">
              <div class="ml-3 mr-3 h-[32px]" v-for="(item, i) in haedNav" :key="i">
                <el-popover v-if="item.title == '家族' || item.title == '消息'" class="navClickBox" @show="navClick(item)" @hide="navClickHide()" placement="bottom-start" width="384" trigger="click">
                  <div class="navClick" v-if="navClickItem.title == item.title">
                    <headerFamily v-if="navClickItem.title == '家族'"></headerFamily>
                    <headerMsg v-if="navClickItem.title == '消息'"></headerMsg>
                  </div>
                  <div slot="reference" style="cursor: pointer">
                    <div class="w-6 h-6 inline-block">
                      <img class="w-full h-full nav-icon" :src="item.icon" />
                    </div>
                    <span class="t-14 leading-none ml-2" style="vertical-align: super">{{ item.title }}</span>
                  </div>
                </el-popover>
                <el-popover v-else-if="item.title == '下载APP'" placement="bottom" width="288" trigger="click">
                  <div class="m-4 text-center">
                    <p class="w-64 t-12 mb-2">微信扫一扫</p>
                    <div class="w-30 h-35 inline-block">
                      <img class="w-30 h-30" src="../assets/imgs/Android.png" />
                      <p class="t-12 mt-2">安卓下载</p>
                    </div>
                    <div class="w-30 h-35 ml-4 inline-block">
                      <img class="w-30 h-30" src="../assets/imgs/Ios.png" />
                      <p class="t-12 mt-2">苹果下载</p>
                    </div>

                    <div class="border-top-[1px] border-hex-[#ccc] mt-[10px] pt-[10px]"><a class="underline" href="http://xxxx.yiyougame.net/pc.zip" target="_blank">PC端下载</a></div>
                  </div>
                  <div slot="reference" style="cursor: pointer">
                    <div class="w-6 h-6 inline-block">
                      <img class="w-full h-full nav-icon" :src="item.icon" />
                    </div>
                    <span class="t-14 leading-none ml-2" style="vertical-align: super">{{ item.title }}</span>
                  </div>
                </el-popover>
                <div v-else class="text-center cursor-pointer" @click="haedNavClick(item)">
                  <div class="w-6 h-6 inline-block">
                    <img class="w-full h-full nav-icon" :src="item.icon" />
                  </div>
                  <span class="t-14 leading-none ml-2" style="vertical-align: super">{{ item.title }}</span>
                </div>
              </div>
            </div>

            <div class="nav-login flex">
              <template v-if="user.id">
                <div>
                  <div class="w-8 h-8 inline-block align-middle -mt-1 cursor-pointer">
                    <el-image class="w-full h-full rounded-full align-top" @click="openInfo" :src="user.headimgurl">
                      <div slot="error" class="image-slot text-center" style="line-height: 60px; font-size: 50px">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <!-- <span @click="creatRoom()" class="w-15 text-center -mt-10 h-3.5 leading-4 float-left t-12 t-blue cursor-pointer">我的房间</span> -->
                  </div>
                  <div class="inline-block t-14 ml-2">{{ user.nickname }}</div>
                </div>
              </template>
              <template v-else>
                <div class="ml-4 w-35 inline-block t-14 t-fff -mt-1.5" @click="() => openLogin()">登录/注册</div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <div v-if="navPopoverVisible" class="navPopoverWrapper"></div>
    <div class="footer">
      <div class="container mx-auto">
        <div class="text-center">
          <a href="http://www.beian.gov.cn/" target="_blank">
            <img
              src="data:image/gif;base64,R0lGODlhFAAUAHcAMSH+GlNvZnR3YXJlOiBNaWNyb3NvZnQgT2ZmaWNlACH5BAEAAAAALAEAAAASABQAhwAAAAAAAAAAMwAAZgAAmQAAzAAA/wAzAAAzMwAzZgAzmQAzzAAz/wBmAABmMwBmZgBmmQBmzABm/wCZAACZMwCZZgCZmQCZzACZ/wDMAADMMwDMZgDMmQDMzADM/wD/AAD/MwD/ZgD/mQD/zAD//zMAADMAMzMAZjMAmTMAzDMA/zMzADMzMzMzZjMzmTMzzDMz/zNmADNmMzNmZjNmmTNmzDNm/zOZADOZMzOZZjOZmTOZzDOZ/zPMADPMMzPMZjPMmTPMzDPM/zP/ADP/MzP/ZjP/mTP/zDP//2YAAGYAM2YAZmYAmWYAzGYA/2YzAGYzM2YzZmYzmWYzzGYz/2ZmAGZmM2ZmZmZmmWZmzGZm/2aZAGaZM2aZZmaZmWaZzGaZ/2bMAGbMM2bMZmbMmWbMzGbM/2b/AGb/M2b/Zmb/mWb/zGb//5kAAJkAM5kAZpkAmZkAzJkA/5kzAJkzM5kzZpkzmZkzzJkz/5lmAJlmM5lmZplmmZlmzJlm/5mZAJmZM5mZZpmZmZmZzJmZ/5nMAJnMM5nMZpnMmZnMzJnM/5n/AJn/M5n/Zpn/mZn/zJn//8wAAMwAM8wAZswAmcwAzMwA/8wzAMwzM8wzZswzmcwzzMwz/8xmAMxmM8xmZsxmmcxmzMxm/8yZAMyZM8yZZsyZmcyZzMyZ/8zMAMzMM8zMZszMmczMzMzM/8z/AMz/M8z/Zsz/mcz/zMz///8AAP8AM/8AZv8Amf8AzP8A//8zAP8zM/8zZv8zmf8zzP8z//9mAP9mM/9mZv9mmf9mzP9m//+ZAP+ZM/+ZZv+Zmf+ZzP+Z///MAP/MM//MZv/Mmf/MzP/M////AP//M///Zv//mf//zP///wECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwECAwj/AAEIHAggWiBTrAgqHFiqFB09VkrpSbhQYCATejx1EtbQRKmKAKJYQRZJ2KVIyFixWFiqxRWSmC55ihTpGRYWzwgKGlDqEi+fJdsIC0TgysBnRPVgihQzZqRLqwYIygmNlCmeMzElI5UMZakBgaqxYoWsVFFndDB5Wkvq2Z4BZKE98wRNwYA5yS5hwiRs7QAXq8qW8oTMy4BIuzzJ9MQr0gAsc1dVfVZKgYmlmIRiUgL32bNVpUiVemZ4wAATUUwP8OKZFLSqyTxRDnQlNJYWXka3XXXtmWjZnp0he1bNGandAqP5ju27+Srmpa4x/Ex91WdS1kEPXGXNetvvW313FLdGsHnr4c9Uea4oUaBvgdA+DgwIADs="
              alt=""
            />
            浙公网安备33028302000534号
          </a>
          <span class="mx-[5px]">|</span>
          <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">浙ICP备2021024822号-2</a>
          <span class="mx-[5px]">|</span>
          <span class="mx-[5px]">宁波易游智能科技有限公司 版权所有 ©2022-2023</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Popup } from "../components/Popup";
import { PopupSleek } from "../components/PopupSleek";
import headerMsg from "../views/headerMsg";
import headerFamily from "../views/headerFamily";
import { getStore } from "@/utils/store";
import { merge_search, searhList, cleanSarhList, search_labels, search_all } from "@/api/index";
import bus from "@/utils/bus";
export default {
  name: "layout",
  components: { headerMsg, headerFamily },
  data() {
    const user = getStore({ name: "user" }) || {};
    return {
      user,
      keywords: "",
      haedNav: [
        { title: "下载APP", icon: require("../assets/imgs/download.png") },
        { title: "家族", key: "family", icon: require("../assets/imgs/clan.png") },
        { title: "消息", icon: require("../assets/imgs/message.png") },
        { title: "我要出售", key: "sell", icon: require("../assets/imgs/mySell.png") },
        // { title: "用户帮助", icon: require("../assets/imgs/help.png") },
        /* { title: "订单", icon: require("../assets/imgs/orders.png") }, */
        { title: "充值", key: "recharge", icon: require("../assets/imgs/recharge.png") },
        { title: "我的房间", key: "room", icon: require("../assets/imgs/myHome.png") },
      ],
      navPopoverVisible: false,

      navClickItem: {},
      showSearhList: false,
      searhList: [],
      showSearh: false,
      searhData: {},
      // isHome: false,
    };
  },
  computed: {
    isHome() {
      return this.$route.name === "home";
    },
  },
  // watch: {
  //   $route: {
  //     handler() {
  //       console.log(this.$route, "1111");
  //       if()
  //     },
  //     immediate: true,
  //   },
  // },
  mounted() {
    getStore({ name: "user" }) ? (this.user = getStore({ name: "user" })) : "";
    console.log(this.user);
    bus.$on("refreshUser", () => {
      this.user = getStore({ name: "user" }) || {};
    });
  },
  methods: {
    async rnterRoom(list) {
      this.$router.push({ name: "room", params: { channelId: list.uid } });
    },
    async cleanSarhList() {
      const { data } = await cleanSarhList({ user_id: this.user.id });
      this.searhList = [];
    },
    clickSearhList(keywords) {
      this.keywords = keywords;
      this.mergeSearch();
    },
    async handleInputBlur() {
      setTimeout(() => {
        this.showSearhList = false;
      }, 300);
    },
    async handleInputFocus() {
      const { data } = await searhList({ user_id: this.user.id });
      this.searhList = data.histor;
      this.showSearhList = true;
    },
    async mergeSearch() {
      if (this.keywords == "") {
        return;
      }
      const { data } = await search_all({ user_id: this.user.id, keywords: this.keywords, type: "room", page: 1 });
      this.searhData.rooms = data;
      this.showSearhList = false;
      this.showSearh = true;
    },
    async creatRoom() {
      await Popup(() => import("../views/roomInfo/index.vue"));
    },
    navClickHide() {
      this.navPopoverVisible = false;
      this.familyListFilter = [];
    },
    navClick(item) {
      if (!getStore({ name: "user" })) {
        this.awakenLoginDialog();
        return;
      }
      this.navPopoverVisible = true;
      this.navClickItem = item;
    },
    async haedNavClick(item) {
      const key = item.key || item.title;
      if (!getStore({ name: "user" })) {
        this.awakenLoginDialog();
        return;
      }
      if (key == "recharge") {
        await PopupSleek(() => import("../views/rechargeMonery/index.vue"));
      }
      if (key == "room") {
        this.creatRoom();
      }
      if (key === "sell") {
        await PopupSleek(() => import("../views/trade/create/popup.choose.vue"));
      }
    },
    async openLogin() {
      await Popup(() => import("../views/login/index.vue"));
    },
    async openInfo() {
      await Popup(() => import("../views/infoBox/index.vue"));
    },
  },
  beforeDestroy() {
    bus.$off("refreshUser");
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.layout {
  &.is-home {
    background-image: url("/public/static/images/home-bg.png");
    background-position: top center;
    background-size: 100%;
    background-repeat: no-repeat;
    .header {
      background-color: transparent;
      color: #fff;

      .search-box {
        background-color: #fff;
        border: none;
        color: #333;
      }
      .nav {
        .nav-icon {
          filter: invert(0);
        }
      }
      .nav-login {
        color: #fff;
      }
    }
    .header-app-name {
      color: #fff !important;
    }
  }

  .header {
    // background: #212126;
    background-color: #fff;
    height: 72px;
    color: #333;
    // line-height: 72px;

    .header-app {
      display: flex;
      align-items: center;

      .header-app-logo {
        width: 40px;
        height: 40px;
        border-radius: 4px;
        overflow: hidden;
      }

      .header-app-name {
        margin-left: 12px;
        font-size: 16px;
        color: #333;
      }
    }

    .header-nav {
      height: 72px;
      padding: 20px 0;
      box-sizing: border-box;
    }

    .search {
      // display: inline-block;
      position: relative;
      height: 32px;
      // width: 300px;
      text-align: right;

      .search-box {
        display: inline-block;
        background-color: #f2f3f5e6;
        border: 1px solid #999;
        border-radius: 32px;
        height: 32px;
        line-height: 32px;
        vertical-align: top;

        .search-input {
          display: inline-block;
          input {
            background-color: transparent;
            border: 0;
            margin: 0;
            width: 220px;
            height: 30px;
            padding: 5px 15px;
            box-sizing: border-box;
            outline: none;
            font-size: 14px;
            vertical-align: top;
          }
        }
        .search-submit {
          display: inline-block;
          width: 30px;
          button {
            height: 32px;
            width: 30px;
            border: 0;
            text-align: center;
          }
        }
      }
    }

    .nav {
      margin-right: 12px;
      // padding: 10px 0;
      .el-popover__reference {
        text-align: center;
      }

      .nav-icon {
        filter: invert(100%);
      }
    }

    .nav-login {
      color: #333;
    }
  }
}
.el-popover {
  border-radius: 10px;
  .navClick {
    width: 384px;
    height: 708px;

    .search {
      .search-input {
      }
      .el-form {
        .el-form-item {
          display: inline-flex;
          text-align: left;
          .el-form-item__content {
            line-height: 76px;
          }
        }

        .search-box {
          margin: 0 0 0 20px;

          .el-input__inner {
            height: 40px;
            outline: 0;
            padding: 0 15px;
            border-radius: 50px 0 0 50px;
            text-align: left;
            color: #fff;
            // border: 0;
            // background-color: hsla(0, 0%, 100%, 0.1);
          }
        }

        .search-submit {
          .el-button {
            width: 64px;
            padding-left: 16px;
            border-radius: 0 50px 50px 0;
            height: 40px;
            background-image: linear-gradient(90deg, #00b4ff, #1189ff);
          }
        }
      }
    }

    .clansBox {
      height: 631px;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .messageTab:hover {
      background: #00b4ff;
      color: #fff;
    }
    .messageTabActive {
      color: #00b4ff;
    }
    .clan {
      line-height: 76px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.06);
      .clanTitle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .el-button {
        width: 72px;
        border-radius: 50px;
        height: 32px;
        line-height: 8px;
        background-image: linear-gradient(90deg, #00b4ff, #1189ff);
      }
    }
  }
  .popper__arrow {
    display: none;
  }
}
.navPopoverWrapper {
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
}
</style>
