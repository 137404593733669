import axios from "axios";
import { Message } from "element-ui";
// import Bowser from "bowser";
import { getStore } from "@/utils/store";
// import dayjs from "dayjs";
import { getToken, getUA } from "@/utils/auth";
// import { toFormData, guid } from "@/utils";
// import router from "@/router";
import { Popup } from "@/components/Popup";

// window.Bowser = Bowser;
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    "Content-Type": "multipart/form-data",
    // "Content-Type": "x-www-form-urlencoded",
  },
  withCredentials: true,
});

service.interceptors.request.use(
  (config) => {
    const token = getToken();
    const user = getStore({ name: "user" }) || {};
    const uainfo = getUA();
    config.headers["device"] = `${uainfo.device || "web"}`;
    config.headers["version"] = uainfo.version || "";
    if (token) {
      config.headers.token = token;
    }

    config.data = config.data || {};
    if (!config.data.user_id) {
      config.data.user_id = user.user_id;
    }

    return config;
  },
  () => {}
);
service.interceptors.response.use(
  async (res) => {
    const { code, message } = res.data;
    if (code === 1) {
      return res.data;
    }

    if (code === 2) {
      await Popup(() => import("@/views/login/index.vue"));
      window.location.reload();
      // router.replace({
      //   name: "login",
      //   query: {
      //     redirect: router.currentRoute.fullPath,
      //   },
      // });
      return Promise.reject(message);
    }

    Message.error(message || "未知错误");
    return Promise.reject(message);
  },
  (err, config) => {
    Message.error(err.msg || "未知错误,请联系开发者");
    return Promise.reject(err, config);
  }
);

export default service;
