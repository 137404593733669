import request from "./request";
/** 检查未读消息*/
export function unreadMessage(data) {
  return request.post("/api/unreadMessage", data);
}

/** 好友,关注,粉丝
  @Field("user_id") String user_id,
  @Field("type") String type,
  @Field("page") String page
*/
export function userFriend(data) {
  return request.post("/api/userFriend", data);
}

/** 添加关注
  @Field("followed_user_id") String followed_user_id,
*/
export function follow(data) {
  return request.post("/api/follow", data);
}

/** 取消关注
  @Field("followed_user_id") String followed_user_id,
*/

export function cancel_follow(data) {
  return request.post("/api/cancel_follow", data);
}

/** 好友列表is_black
*/
export function friend_list(data) {
  return request.post("/api/friend_list", data);
}

/** 官方消息
*/
export function official_message(data) {
  return request.post("/api/official_message", data);
}


