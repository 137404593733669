import request from "./request";

/** 获取轮播*/
export function carousel(data) {
  return request.post("/api/carousel", data);
}
/** 获取热门列表*/
export function is_popular(data) {
  return request.post("/api/is_popular", data);
}

/**
 * * 排行榜
 * @Field("class") String type,
   @Field("type") String date,
   @Field("user_id") String user_id);
 */
export function ranking(data) {
  return request.post("/api/ranking", data);
}

/**
 * 搜索标签
 * keywords 关键词
 */
export function search_labels({ keywords }) {
  return request({ url: "/api/search_labels", method: "post", params: { keywords } });
}

/**
 * 搜索记录
 * user_id
 */
export function searhList({ user_id }) {
  return request({ url: "/api/searhList", method: "post", params: { user_id } });
}

/**
 * 清空记录
 * user_id
 */
export function cleanSarhList({ user_id }) {
  return request({ url: "/api/cleanSarhList", method: "post", params: { user_id } });
}

/**
 * 搜索
 * keywords 关键词
 * user_id
 */
export function merge_search({ user_id, keywords }) {
  return request({ url: "/api/merge_search", method: "post", params: { user_id, keywords } });
}

/**
 * 搜索
 * keywords 关键词
 * user_id
 */
export function search_all({ user_id, keywords, type, page }) {
  return request({ url: "/api/search_all", method: "post", params: { user_id, keywords, type, page } });
}

/**
 * 搜索
 * keywords 关键词
 * user_id
 */
export function oss_upload(file) {
  return request({ url: "/api/oss_upload", method: "post", data: { file } });
}

/**
 *
 * @param url 目标下载接口
 * @param query 查询参数
 * @param fileName 文件名称
 * @returns {*}
 */
export function downBlobFile(url, query, fileName) {
  return request({
    url: url,
    method: "get",
    responseType: "blob",
    params: query,
  }).then((response) => {
    // 处理返回的文件流
    const blob = response.data;
    if (blob && blob.size === 0) {
      this.$notify.error("内容为空，无法下载");
      return;
    }
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    window.setTimeout(function () {
      URL.revokeObjectURL(blob);
      document.body.removeChild(link);
    }, 0);
  });
}
