import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import "windi.css";
import "./styles/index.scss";
import router from "./router";
import store from "./store";
import $http from "./api/request";
import RtmClient from "@/utils/agora/rtm-client"; // 声网端
import RtcClient from "@/utils/agora/index"; // 声网端
import { Popup } from "@/components/Popup";

import directives from "./directives";
// import './registerServiceWorker'

Vue.use(ElementUI, { size: "small" });
Vue.use(directives);
Vue.prototype.$http = $http;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.prototype.awakenLoginDialog = async function () {
  await Popup(() => import("@/views/login/index.vue"));
};

new RtcClient();
new RtmClient();
