<template>
  <div class="hasClan headerMsg">
    <div class="flex border-bottom-[1px] border-color-[#ccc]">
      <div class="clan h-25 px-8 py-5 text-center w-1/3 cursor-pointer messageTab" :class="messageTabChecked.title == list.title ? 'messageTabActive' : ''" @click="messageTabClick(list)" v-for="(list, j) in messageArr" :key="j">
        <div class="h-10 text-center">
          <i class="uu-icon text-[40px]" :class="list.icon"></i>
          <!-- <img class="w-full h-full" :src="list.icon" /> -->
        </div>
        <p class="t-12 leading-none mt-4">
          {{ list.title }}
        </p>
      </div>
    </div>
    <div class="clansBox" style="height: 607px">
      <el-scrollbar style="height: 100%">
        <div v-if="messageTabChecked.type < 1">
          <el-collapse accordion class="border-none">
            <div class="flex" style="border-bottom: 1px solid rgba(255, 255, 255, 0.06)" v-for="(list, j) in messageTabChecked.list" :key="j">
              <div class="ml-3 mr-2 w-auto clanTitle">
                <div class="leading-6 t-14 mt-2 overflow-ellipsis overflow-hidden">
                  <el-collapse-item>
                    <template slot="title">
                      <span class="w-53 t-14 inline-block overflow-ellipsis overflow-hidden">{{ list.title }}</span
                      ><span class="t-12 t-gray align-top">{{ list.updated_at }}</span>
                    </template>
                    <div class="leading-6 t-12 overflow-ellipsis overflow-hidden" v-html="list.content"></div>
                  </el-collapse-item>
                </div>
              </div>
            </div>
          </el-collapse>
        </div>
        <div v-else-if="messageTabChecked.type == 1">
          <div class="h-19 clan" v-for="(list, j) in messageTabChecked.list" :key="j">
            <div class="flex">
              <div class="w-11 h-11 ml-5">
                <img class="w-full h-full rounded-full" :src="list.headimgurl" />
              </div>
              <div class="ml-3 mr-2 h-19 w-52 clanTitle">
                <div class="leading-6 t-14 mt-7 overflow-ellipsis overflow-hidden">
                  {{ list.nickname }}
                </div>
              </div>
              <div class="t-12 t-gray">
                <span class="px-3 py-1 rounded-full" style="border: 1px solid #999">已关注</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="messageTabChecked.type == 2">
          <div class="h-19 clan" v-for="(list, j) in messageTabChecked.list" :key="j">
            <div class="flex">
              <div class="w-11 h-11 ml-5">
                <img class="w-full h-full rounded-full" :src="list.headimgurl" />
              </div>
              <div class="ml-3 mr-2 h-19 w-52 clanTitle">
                <div class="leading-6 t-14 mt-7 overflow-ellipsis overflow-hidden">
                  {{ list.nickname }}
                </div>
              </div>
              <div class="t-12 t-blue">
                <span v-if="messageArr[1].list.filter((item) => item.id == list.id).length > 0" class="px-3 py-1 t-12 t-gray rounded-full cursor-pointer" style="border: 1px solid #999" @click="cancelFollowClick(list)">已关注</span>
                <span v-else class="px-4.5 py-1 t-12 t-gray rounded-full cursor-pointer" style="border: 1px solid #118bff" @click="followClick(list)">关注</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="messageTabChecked.type == 3">
          <div class="h-19 clan" v-for="(list, j) in messageTabChecked.list" :key="j">
            <div class="flex">
              <div class="w-11 h-11 ml-5">
                <img class="w-full h-full rounded-full" :src="list.headimgurl" />
              </div>
              <div class="ml-3 mr-2 h-19 w-52 clanTitle">
                <div class="leading-6 t-14 mt-7 overflow-ellipsis overflow-hidden">
                  {{ list.nickname }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { getStore } from "@/utils/store";
import { unreadMessage, userFriend, follow, cancel_follow, friend_list, official_message } from "@/api/message";
export default {
  name: "headerMsg",
  data() {
    const user = getStore({ name: "user" });
    return {
      user,
      messageArr: [
        { title: "消息", icon: "icon-message", type: 0, list: [] },
        { title: "关注", icon: "icon-Star", type: 1, list: [] },
        { title: "粉丝", icon: "icon-wode-wodefensi", type: 2, list: [] },
        /* { title: "推荐", icon: require("../../assets/imgs/commend.png"), type: 3, list: [] }, */
      ],
      messageTabChecked: {},
    };
  },
  methods: {
    messageTabClick(list) {
      this.messageTabChecked = list;
    },
    init() {
      this.unreadMessage();
      this.friendList();
      this.userFriendType2();
      this.userFriendType3();
      this.officialMessage();
    },
    async officialMessage() {
      const { data } = await official_message({ user_id: this.user.id, page: 1 });
      this.messageArr[0].list = data;
      console.log(data, this.user);
    },
    async unreadMessage() {
      /* const { data } = await unreadMessage({});
      this.messageArr[0].list = data;
      console.log(data); */
    },
    async friendList() {
      /* console.log(this.user);
      const { data } = await friend_list({});
      this.messageArr[0].list = data;
      console.log(data); */
    },
    async userFriendType1() {
      const { data } = await userFriend({ type: 1 });
      this.messageArr[3].list = data;
      console.log(data);
    },
    async userFriendType2() {
      const { data } = await userFriend({ type: 2 });
      this.messageArr[1].list = data;
      console.log(data);
    },
    async userFriendType3() {
      const { data } = await userFriend({ type: 3 });
      this.messageArr[2].list = data;
      console.log(data);
    },
    async cancelFollowClick(list) {
      this.$confirm("是否取消关注?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          const { data } = await cancel_follow({ followed_user_id: list.ry_uid });
          console.log(data);
        })
        .catch(() => {});
    },
    async followClick(list) {
      const { data } = await follow({ followed_user_id: list.ry_uid });
      console.log(data);
    },
  },
  mounted() {
    console.log(111111111);
    this.messageTabChecked = this.messageArr[0];
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.headerMsg {
  border-radius: 10px;
  overflow: hidden;
  .clansBox {
    .el-scrollbar {
      margin-right: 1px;
    }
    ::v-deep.el-collapse-item__header {
      background: none;
    }
    ::v-deep.el-collapse-item__arrow {
      color: #fff;
    }
    ::v-deep.el-icon-arrow-right {
      margin-left: 4px;
    }
    ::v-deep.el-collapse-item__wrap {
      background-color: rgb(101 150 181 / 20%);
      border-radius: 5px;
      border: none;
      margin-bottom: 10px;
    }
    ::v-deep.el-collapse-item__content {
      padding: 2px 8px;
    }
  }
}
</style>
