/* eslint-disable no-undef */
import AgoraRTC from "agora-rtc-sdk-ng";
import { AGORA_KEY } from "@/const/config";
/*
 *  These procedures use Agora Voice Call SDK for Web to enable local and remote
 *  users to join and leave a Voice Call channel managed by Agora Platform.
 */

/*
 *  Create an {@link https://docs.agora.io/en/Voice/API%20Reference/web_ng/v4.11.0/interfaces/iagorartcclient.html|AgoraRTCClient} instance.
 *
 * @param {string} mode - The {@link https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/clientconfig.html#mode| streaming algorithm} used by Agora SDK.
 * @param  {string} codec - The {@link https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/clientconfig.html#codec| client codec} used by the browser.
 */

window.$RTC = null;

export default class {
  constructor() {
    window.$RTC = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
  }
}

/*
 * Create an AgoraRTCClient object.
 */
const localTracks = {
  audioTrack: null,
};

/*
 * On initiation no users are connected.
 */
var remoteUsers = {};

AgoraRTC.onAutoplayFailed = () => {
  alert("click to start autoplay!");
};

AgoraRTC.onMicrophoneChanged = async (changedDevice) => {
  // When plugging in a device, switch to a device that is newly plugged in.
  if (changedDevice.state === "ACTIVE") {
    localTracks.audioTrack.setDevice(changedDevice.device.deviceId);
    // Switch to an existing device when the current device is unplugged.
  } else if (changedDevice.device.label === localTracks.audioTrack.getTrackLabel()) {
    const oldMicrophones = await AgoraRTC.getMicrophones();
    oldMicrophones[0] && localTracks.audioTrack.setDevice(oldMicrophones[0].deviceId);
  }
};

/*
 * Join a channel, then create local audio tracks and publish them to the channel.
 */
export async function join(options) {
  options.appid = AGORA_KEY;

  // Add an event listener to play remote tracks when remote user publishes.
  window.$RTC.on("user-published", handleUserPublished);
  window.$RTC.on("user-unpublished", handleUserUnpublished);

  try {
    await window.$RTC.join(options.appid, options.channel, options.token || null, options.uid || null);
  } catch (error) {
    if (["INVALID_OPERATION"].includes(error.code)) {
      alert("抱歉,没有找到麦克风设备");
    }
  }

  localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
  // Publish the local audio tracks to the channel.
  await window.$RTC.publish(Object.values(localTracks));
  // console.log("publish success");

  closeMic();
}

/*
 * Stop all local and remote tracks then leave the channel.
 */
export async function leave() {
  // console.log(localTracks, "localTracks");
  for (let trackName in localTracks) {
    var track = localTracks[trackName];
    if (track) {
      track.stop();
      track.close();
      localTracks[trackName] = undefined;
    }
  }

  // Remove remote users and player views.
  remoteUsers = {};

  // leave the channel
  await window.$RTC.leave();

  // console.log("客户下麦成功");
}

export function openMic() {
  localTracks.audioTrack.setEnabled(true);
}
export function closeMic() {
  localTracks.audioTrack.setEnabled(false);
}

export function setVolume(volume) {
  console.log(volume);
  localTracks.audioTrack.setVolume(volume);
}

/*
 * Add the local use to a remote channel.
 *
 * @param  {IAgoraRTCRemoteUser} user - The {@link  https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/iagorartcremoteuser.html| remote user} to add.
 * @param {trackMediaType - The {@link https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/itrack.html#trackmediatype | media type} to add.
 */
async function subscribe(user, mediaType) {
  // const uid = user.uid;
  // subscribe to a remote user
  await window.$RTC.subscribe(user, mediaType);
  // console.log("subscribe success");
  if (mediaType === "audio") {
    user.audioTrack.play();
  }
}

/*
 * Add a user who has subscribed to the live channel to the local interface.
 *
 * @param  {IAgoraRTCRemoteUser} user - The {@link  https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/iagorartcremoteuser.html| remote user} to add.
 * @param {trackMediaType - The {@link https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/itrack.html#trackmediatype | media type} to add.
 */
function handleUserPublished(user, mediaType) {
  const id = user.uid;
  remoteUsers[id] = user;
  subscribe(user, mediaType);
}

/*
 * Remove the user specified from the channel in the local interface.
 *
 * @param  {string} user - The {@link  https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/iagorartcremoteuser.html| remote user} to remove.
 */
function handleUserUnpublished(user, mediaType) {
  if (mediaType === "audio") {
    const id = user.uid;
    delete remoteUsers[id];
  }
}
